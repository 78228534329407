
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import OrganizationJson from "seo-modules/OrganizationJson.js";
import checkIfModal from "modules/checkIfModal";

import { isMaintenanceMode } from "modules/utils";
import HomeBody from "pages-sections/home/HomeBody";

// core components

import { PageSeo } from "seo-modules/PageSeo";

const PopUpMessage = dynamic(() =>
  import("components/PopUpMessage/PopUpMessage.js")
);

//const HomeBody = dynamic(() => import('pages-sections/home/HomeBody'));
// sections for this page

const HomePage = (props) => {
  const { seo, ...rest } = props;

  const router = useRouter();
  if (isMaintenanceMode) {
    return <div />;
  }
  const { query } = router;

  return (
    <>
      <PageSeo seo={seo} pageTitle={props.title} ogContent={props.ogContent} />
      {query?.msg && checkIfModal(query.msg) && (
        <PopUpMessage messageKey={query.msg} path={props.path} />
      )}
      <HomeBody {...rest} />
      <OrganizationJson />
    </>
  );
};

HomePage.fullScreen = true;

 async function _getStaticProps(params) {
  const { default: homePageFetcher } = await import(
    "modules/home/homePageFetcher"
  );

  const { locale: lang } = params;
  // do not pass a uri as we test this to establish if the request came from another page
  return homePageFetcher(null, lang);
}

export default HomePage;


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  