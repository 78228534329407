import React from "react";
import { SocialProfileJsonLd, LogoJsonLd } from "next-seo";
import { ROOT_URL } from "settings/global-urls.js";

import { supportedSocialChannels } from "settings/social-links-config";

import { websiteLogoShareable } from "settings/global-website-config";

const OrganizationJson = () => {
  return (
    <>
      <SocialProfileJsonLd
        type="Organization"
        logo={websiteLogoShareable}
        name="geerly"
        url={ROOT_URL}
        sameAs={supportedSocialChannels.map((l) => l.url).filter(Boolean)}
      />
    </>
  );
};

export default OrganizationJson;
